<template>
  <div>
    <div class="nav-bar">
      <van-icon
        name="arrow-left"
        size="20"
        color="#9B9B9B"
        class="left-icon"
        @click="$router.go(-1)"
      />
      <span class="title">查看物流</span>
    </div>
    <div class="logistics-info">
      <!-- 取件人信息 -->
      <div class="take-name">
        <div class="icon-img-box">
          <img src="~@/assets/order/user-name.png" class="icon-img" />
        </div>
        <div class="user-contact">
          <div class="user-name">
            <span class="font-color">取件人姓名：</span>
            <span>{{ userInfo.consignee }}</span>
            <span class="gray-color">{{ userInfo.consignee_tel }}</span>
          </div>
        </div>
      </div>
      <!-- 快递公司 -->
      <div class="courier-company">
        <div class="icon-img-box">
          <img src="~@/assets/order/recipient_Icon@2x.png" class="icon-img" />
        </div>
        <span>{{
          `${userInfo.shipping_name || ""}：${userInfo.express_numbers || ""}`
        }}</span>
        <div class="button">复制</div>
      </div>
      <!-- 收货人地址 -->
      <div class="address">
        <div class="icon-img-box">
          <img src="~@/assets/order/receiving_icon@2x.png" class="icon-img" />
        </div>
        <div>收货地址：{{ userInfo.city_name }}</div>
      </div>
      <van-steps
        direction="vertical"
        :active="0"
        active-color="#ff3d10"
        inactive-color="#6F6F6F"
      >
        <van-step v-for="(item, index) in userInfo.logistics_info" :key="index">
          <div>{{ item.AcceptStation }}</div>
          <p>{{ item.AcceptTime }}</p>
        </van-step>
      </van-steps>
    </div>
  </div>
</template>

<script>
import { getExpressInfo } from "@/api/used";
export default {
  name: "examine-logistics",
  data() {
    return {
      list: [],
      userInfo: {},
      orderId: this.$route.query.orderId || 0,
    };
  },
  async mounted() {
    // console.log(this.orderId);
    this.getExpressInfo();
  },
  methods: {
    async getExpressInfo() {
      const res = await getExpressInfo({ order_id: this.orderId });
      // console.log(res);
      this.userInfo = res.data;
    },
  },
};
</script>

<style lang="less" scoped>
// 顶部导航栏
.nav-bar {
  position: relative;
  background-color: #fff;
  padding: 14px;
  text-align: center;
  border-bottom: 1px solid #f1f1f1;
  .left-icon {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
  }
}
.title {
  font-size: 21px;
}
//物流信息
.logistics-info {
  background-color: #fff;
  padding: 0 12px;
}
// 取件人信息
.take-name {
  display: flex;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid #ededed;
  .user-contact {
    .user-name {
      font-size: 17px;
      .gray-color {
        padding-left: 10px;
      }
    }
  }
  .take-address {
    margin-top: 4px;
    .segmentation {
      padding: 0 10px;
      font-size: 14px;
      color: #707070;
    }
  }
}
// 快递公司
.courier-company {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 17px;
  padding: 12px 0;
  border-bottom: 1px solid #ededed;
}
// 申通快递派件员
.courier-employees {
  position: relative;
  display: flex;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid #ededed;
}
// 收货人地址
.address {
  display: flex;
  align-items: center;
  font-size: 17px;
  padding: 12px 0;
  border-bottom: 1px solid #ededed;
}
// 图标样式
.icon-img-box {
  width: 28px;
  margin-right: 10px;
  flex-shrink: 0;
  .icon-img {
    width: 100%;
    display: block;
  }
}
//字体样式
.font-color {
  font-size: 17px;
}
.gray-color {
  color: #aaaaaa;
  font-size: 14px;
}
.deep-gray-color {
  color: #6f6f6f;
  font-size: 14px;
}
.orange-color {
  color: #ed301d;
}
// 按钮样式
.button {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  padding: 4px 14px;
  border: 1px solid #aaaaaa;
  border-radius: 100px;
  font-size: 14px;
}
/deep/.van-step__title {
  font-weight: normal !important;
}
</style>
